import React, { useEffect, useState, useImperativeHandle } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import "./UserProfile.css";
import { STORAGE_SERVER, API_SERVER } from "../config";

const UserProfile = React.forwardRef(({
  openMedia,
  friendsRequestsData,
  friendsData,
  refresh_friends,
  openChatWithUser
}, ref) => {
  function formatDateTime(date) {
    const givenDate = new Date(date);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - givenDate.getTime());

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffYears = Math.floor(diffDays / 365);

    let formattedTime = givenDate.toLocaleTimeString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });

    let formattedDate = "";
    if (givenDate.getDate() !== currentDate.getDate()) {
      formattedDate = givenDate.toLocaleDateString("ru-RU", {
        day: "numeric",
        month: "short",
      });
    }

    let formattedYear = "";
    if (diffYears >= 1) {
      formattedYear = ` ${givenDate.getFullYear()}`;
    }

    return `${formattedTime} ${formattedDate}${formattedYear}`;
  }

  const [curUserId, setCurUserId] = useState(-1)

  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState([]);
  const [userPostsData, setUserPostsData] = useState();

  const [otherUserFriendStatus, setOtherUserFriendStatus] = useState("");

  const request_user_page = async (target_user) => {
    
    var session_token = Cookies.get("authToken");

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_user`,
        { session_token, target_user }
      );
      if (response) {
        console.log("Other profile data update");
        setUserData(response.data);
        setCurUserId(response.data.id);
        setOtherUserFriendStatus("unknown");

        if (
          friendsRequestsData.sent_requests.some((user) => user.user_id === response.data.id)
        ) {
          setOtherUserFriendStatus("requested");
        }
        if (friendsData.friends.some((friend) => friend.user_id === response.data.id)) {
          setOtherUserFriendStatus("friend");
        }
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
    try {
      const formData = new FormData();
      formData.append("target_user_id", target_user);
      formData.append("session_token", Cookies.get("authToken"));
      formData.append("page", 1);
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_user_posts`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Other posts data update");
        setUserPostsData(response.data);
        setTotalPages(response.data.total_pages)
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  useImperativeHandle(ref, () => ({
    request_user_page,
  }));

  const handleSendFriendRequest = async () => {
    var session_token = Cookies.get("authToken");
    var target_user_id = curUserId;
    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/send_friend_request`,
        { session_token, target_user_id }
      );
      if (response) {
        console.log("Success send friend request");
        refresh_friends();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const handleCreatePrivateChat = async () => {
    var session_token = Cookies.get("authToken");
    var user_id = curUserId;
    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/create_private_chat`,
        { session_token, user_id }
      );
      if (response) {
        console.log("Success opened chat");
        openChatWithUser(user_id)
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const refresh_user_posts_info = async (target_page) => {
    if (parseInt(curUserId)===-1) {
      return;
    }
    try {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("target_user_id", curUserId);
      formData.append("session_token", Cookies.get("authToken"));
      formData.append("page", target_page);
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_user_posts`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response && response.data) {
        console.log(`User posts update`);
        const newPosts = response.data.posts.filter(post => !userPostsData?.posts.some(existingPost => existingPost.id === post.id));
      
        if (!userPostsData || !Array.isArray(userPostsData.posts)) {
          setUserPostsData(response.data);
        } else {
          
          setUserPostsData(prevPosts => ({
            ...prevPosts,
            posts: [...newPosts, ...prevPosts.posts],
            total_pages: response.data.total_pages
          }));
        }
        setTotalPages(response.data.total_pages);
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    } finally {
      setIsLoading(false)
    }
  };

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.body.offsetHeight;
      if (bottomOfWindow && !isLoading && page <= totalPages) {
        refresh_user_posts_info(page+1);
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    refresh_user_posts_info(1);
  }, [])

  return (
    <div className="UserProfile">
      {userData.length === 0 ? (
        <h1 className="profile-page-posts-post-container">Загрузка</h1>
      ) : (
        <>
          <div className="profile-main-info-container">
            <div
              className="profile-avatar"
            >
              <img
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
                alt="Avatar"
                src={`https://${window.location.hostname}/api/storage/avatars/${
                  userData.avatars?.split(";")[userData.avatars?.split(";").length - 1] || 'avatar_placeholder.png'
                }`}
              />
            </div>
            <div className="profile-text">
              <h2 className="profile-name">
                {userData.display_name || userData.username || "Анон"}
              </h2>
              <div className="profile-description">
                {userData.description || "Тут пустовато"}
              </div>
              {userData.username === 'kitsunet' ? ('') : (<>
                {otherUserFriendStatus === "unknown" && (
                <button
                  className="kitsunet-design-input-button"
                  onClick={handleSendFriendRequest}
                >
                  Отправить запрос дружбы
                </button>
              )}
              {otherUserFriendStatus === "requested" && (
                <h3>Запрос в друзья отправлен</h3>
              )}
              {otherUserFriendStatus === "friend" && (
                <button
                  className="kitsunet-design-input-button"
                  onClick={handleCreatePrivateChat}
                >
                  Открыть чат
                </button>
              )}
              </>)}
              
            </div>
          </div>
          <div className="profile-page-content-container">
            <div className="profile-page-posts-container">
              {userPostsData && (
                <>
                  {userPostsData.posts.length === 0 ? (
                    <h2 className="profile-page-posts-post-container-no">
                      Пользователь не выкладывал посты
                    </h2>
                  ) : (
                    [...userPostsData.posts].reverse().map((post) => (
                      <div
                        key={post.id}
                        className="profile-page-posts-post-container"
                      >
                        <div className="profile-page-posts-post-text">
                          {post.text}
                        </div>
                        {post.media && (
                          <div className="profile-page-posts-post-media">
                            {post.media.split(";").map((mediaItem) => (
                              <img
                                className="profile-page-posts-post-media-src"
                                key={mediaItem}
                                src={`https://${window.location.hostname}/api/storage/media/${mediaItem}`}
                                alt={mediaItem}
                                onClick={() =>
                                  openMedia(mediaItem)
                                }
                              />
                            ))}
                          </div>
                        )}
                        <div className="profile-page-posts-post-footer">
                          {formatDateTime(post.created_at)}
                        </div>
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
            <div className="profile-page-additional-panel">
              <div className="profile-page-additional-panel-info">
                <div className="profile-page-additional-panel-info-container">
                  <div className="profile-page-additional-panel-info-title">
                    Рейтинг:
                  </div>
                  <div className="profile-page-additional-panel-info-text">
                    {userData.social_score}
                  </div>
                </div>

                <div className="profile-page-additional-panel-info-container">
                  <div className="profile-page-additional-panel-info-title">
                    Баланс:
                  </div>
                  <div className="profile-page-additional-panel-info-text">
                    {userData.ticks}
                  </div>
                </div>

                {userData.location && (
                  <div className="profile-page-additional-panel-info-container">
                    <div className="profile-page-additional-panel-info-title">
                      Местоположение:
                    </div>
                    <div className="profile-page-additional-panel-info-text">
                      {userData.location}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default UserProfile;
