import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import "./App.css";

import Main from "./Main";
import Auth from "./Auth";
import NotificationComponent from "./components/NotificationComponent";
import Placeholder from "./screens/Placeholder";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = Cookies.get("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <div className="App">
      <NotificationComponent />
      {isAuthenticated ? <Placeholder><Main /></Placeholder> : <Auth />}
    </div>
  );
};

export default App;
