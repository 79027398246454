import "./Messenger.css";
import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import parse from "html-react-parser";

import { API_SERVER, STORAGE_SERVER } from "../config.js";

import axios from "axios";
import Cookies from "js-cookie";
import { useConfirmationDialog } from "../context/ConfirmationDialogContext.js";

import { useSocket } from "../context/SocketContext.js";
import { useNotifications } from "../context/NotificationContext.js";

import sendIcon from "../assets/images/send.svg";

import boardIcon from "../assets/images/board.svg";
import callIcon from "../assets/images/call.svg";
import chatIcon from "../assets/images/chat.svg";

const Messenger = React.forwardRef(({ my_user_id, request_user_page }, ref) => {
  const [curChatTab, setCurChatTab] = useState("chat");
  const { lastMessage } = useSocket();

  const { sendNotification } = useNotifications();

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const parsedData = JSON.parse(lastMessage.data);
      if (parsedData.type === "notify") {
        sendNotification("Сообщение", parsedData.message);
        refresh_chat_data();
        refresh_chats();
      }
      if (parsedData.type === "hello") {
        refresh_chats();
      }
    }
   
  }, [lastMessage]);

  const [isLoading, setIsLoading] = useState(false);

  const { showDialog } = useConfirmationDialog();

  const [chatData, setChatData] = useState(null);
  const [chatsListData, setChatsListData] = useState(null);

  const [currentChatId, setCurrentChatId] = useState(
    localStorage.getItem("currentChatId") || -1
  );

  useEffect(() => {
    localStorage.setItem("currentChatId", currentChatId);
  }, [currentChatId]);

  const [messageText, setMessageText] = useState("");
  const messagesContainerRef = useRef(null);

  const [rawMessageData, setRawMessageData] = useState("");

  

  const attachmentHiddenInputRef = useRef();

  const discordProblemSolving = (text) => {
    const parts = text.split(/(<|>)/);
    const processedParts = parts.map((part) => {
      if (part.startsWith("<")) {
        return `<span><</span>`;
      } else if (part.endsWith(">")) {
        return `<span>></span>`;
      } else {
        return part;
      }
    });
    return processedParts.join("");
  };

  const formatMarkdown = (text) => {
    function replaceWithRegex(regex, replacement) {
      return text.replace(new RegExp(regex, "g"), replacement);
    }
    text = replaceWithRegex(/(\*\*\*)([^*]+)(\*\*\*)/g, "<i><b>$2</b></i>");
    text = replaceWithRegex(/(\*\*)([^*]+)(\*\*)/g, "<b>$2</b>");
    text = replaceWithRegex(/(\*)([^*]+)(\*)/g, "<i>$2</i>");
    text = replaceWithRegex(/(__)(.+?)(__)/g, "<u>$2</u>");
    text = replaceWithRegex(/(~~)(.+?)(~~)/g, "<strike>$2</strike>");
    return text;
  };

  function compareChats(a, b) {
    const lastMessageA = a.last_messages[a.last_messages.length - 1];
    const lastMessageB = b.last_messages[b.last_messages.length - 1];

    if (!lastMessageA || !lastMessageB) {
      return 0;
    }

    return new Date(lastMessageB.send_time) - new Date(lastMessageA.send_time);
  }

  function formatDateTime(date) {
    const givenDate = new Date(date);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - givenDate.getTime());

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffYears = Math.floor(diffDays / 365);

    let formattedTime = givenDate.toLocaleTimeString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });

    let formattedDate = "";
    if (givenDate.getDate() !== currentDate.getDate()) {
      formattedDate = givenDate.toLocaleDateString("ru-RU", {
        day: "numeric",
        month: "short",
      });
    }

    let formattedYear = "";
    if (diffYears >= 1) {
      formattedYear = ` ${givenDate.getFullYear()}`;
    }

    return `${formattedDate} ${formattedTime} ${formattedYear}`;
  }

  const handleSendMessage = async (e) => {
    var session_token = Cookies.get("authToken");
    var target_chat = currentChatId;
    var message_data = messageText;
    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/send_message`,
        { session_token, target_chat, message_data }
      );
      if (response) {
        console.log("Success send message");
        refresh_chats();
        refresh_chat_data();
        setMessageText("");
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const refresh_chats = async () => {
    try {
      var session_token = Cookies.get("authToken");
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_my_chats`,
        { session_token }
      );
      if (response) {
        console.log(`Chats data update`);
        setChatsListData(response.data);
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const refresh_chat_data = async () => {
    const formData = new FormData();
    formData.append("target_chat", currentChatId);
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_chat_data`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success get chat info");

        if (chatData !== response.data) {
          setChatData(response.data);
        }
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const delete_message = async (target_message_id) => {
    const formData = new FormData();
    formData.append("target_message", target_message_id);
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/delete_message`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success delete");
        refresh_chat_data();
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const open_message_context = (e, message_id) => {
    e.preventDefault();
    console.log("Контекстное меню открыто для ", message_id);

    const confirmDeleteMessage = () => {
      delete_message(message_id);
    };

    showDialog("Вы хотите удалить сообщение?", confirmDeleteMessage);
  };

  const handleAttachmentAdd = async (event) => {
    const file = event.target.files[0];
    if (file.size > 20 * 1024 * 1024) {
      alert("Файл слишком большой. Максимальный размер - 20 МБ.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("attachment", file);
      formData.append("session", Cookies.get("authToken"));

      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/upload_attachment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success upload");
        setRawMessageData((rawMessageData) => [
          ...rawMessageData,
          response.data.url,
        ]);
        console.log(rawMessageData)
      }
    } catch (error) {
      if (error.response) {
        console.warn(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const openChatWithUser = (target_user) => {
    const privateChat = chatsListData.chats.find(
      (chat) =>
        chat.type === "private" &&
        chat.members.some((member) => parseInt(member.id) === parseInt(target_user))
    );
    if (privateChat) {
      setCurrentChatId(privateChat.id);
    } else {
      console.log("No private chat found for this user");
    }
  };

  useImperativeHandle(ref, () => ({
    openChatWithUser,
  }));

  const [prevChatData, setPrevChatData] = useState([]);

  useEffect(() => {
    if (chatData) {
      if (
        chatData.length !== 0 &&
        JSON.stringify(chatData) !== JSON.stringify(prevChatData)
      ) {
        scrollToBottom();
        setPrevChatData(chatData);
      }
    }
  }, [chatData]);

  useEffect(() => {
    refresh_chat_data();
  }, [currentChatId]);

  useEffect(() => {
     refresh_chats();
  }, []);

  return (
    <div className="Chat">
      {chatsListData && chatsListData.length !== 0 && (
        <>
          <div className="chat-header" key="chat-header">
            {chatData &&
              chatData.members
                .filter((member) => member.id !== my_user_id)
                .map((user) => (
                  <div
                    className="chat-header-user"
                    key={user.id}
                    onClick={() => request_user_page(user.id)}
                  >
                    <img
                      className="chat-users-user-avatar-src"
                      src={`https://${window.location.hostname}/api/storage/avatars/${
                        user.avatars?.split(";")[
                          user.avatars?.split(";").length - 1
                        ] || "avatar_placeholder.png"
                      }`}
                      alt="USER_AVATAR"
                    />
                    <div className="chat-header-user-text">
                      <h3 key={user.id}>
                        {user.display_name || user.username || "Имя скрыто"}
                      </h3>
                      <h4>
                        {user.username === 'kitsunet' ? '' : formatDateTime(user.last_seen)}
                      </h4>
                    </div>
                  </div>
                ))}
            <div className="dialog-tabs-buttons-container">
              <button
                className={
                  curChatTab === "chat"
                    ? "enabled-chat-tab"
                    : "disabled-chat-tab"
                }
                // onClick={setCurChatTab('chat')}
              >
                <img src={chatIcon} alt="chat"/>
              </button>
              <button
                title="Доски будут добавлены в следующих обновлениях"
                className={
                  curChatTab === "board"
                    ? "enabled-chat-tab"
                    : "disabled-chat-tab"
                }
              >
                <img src={boardIcon} alt="board"/>
              </button>
              <button
                title="Звонки будут добавлены в следующих обновлениях"
                className={
                  curChatTab === "call"
                    ? "enabled-chat-tab"
                    : "disabled-chat-tab"
                }
              >
                <img src={callIcon} alt="call"/>
              </button>
            </div>
          </div>
          <div className="chat-main-container">
            <div className="messages-form-holder">
              <div
                className="messages-container"
                key="chat-messages-container"
                ref={messagesContainerRef}
              >
                {chatData &&
                  chatData.messages.map((message) =>
                    message.sender_id === my_user_id ? (
                      <div
                        key={`${message.id}-outgoing`}
                        className="outgoing-message"
                        onContextMenu={(e) =>
                          open_message_context(e, message.id)
                        }
                      >
                        <div className="message-text">
                          <h4>
                            {parse(
                              formatMarkdown(
                                discordProblemSolving(message.message_data)
                              )
                            )}
                          </h4>
                        </div>
                        <div className="message-time">
                          <h5>{formatDateTime(message.send_time)}</h5>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={`${message.id}-${message.sender_id}`}
                        className="incoming-message"
                      >
                        <div className="message-text">
                          <h4>
                            {parse(
                              formatMarkdown(
                                discordProblemSolving(message.message_data)
                              )
                            )}
                          </h4>
                        </div>
                        <div className="message-time">
                          <h5>{formatDateTime(message.send_time)}</h5>
                        </div>
                      </div>
                    )
                  )}
              </div>
              <div className="chat-footer" key="chat-footer">
                <input
                  className="kitsunet-design-input-field"
                  type="text"
                  placeholder="Введите текст сообщения..."
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  required
                  disabled={isLoading}
                />
                <input
                  ref={attachmentHiddenInputRef}
                  type="file"
                  onChange={handleAttachmentAdd}
                  hidden
                />
                {/* <button
                  className="chat-add-attachment"
                  onClick={() => attachmentHiddenInputRef.current.click()}
                  disabled
                >
                  <img src={icon_attachment_add} alt="" />
                </button> */}
                <button type="submit" onClick={handleSendMessage} disabled={isLoading}>
                  <img className="send-icon" src={sendIcon} alt="send"></img>
                </button>
              </div>
            </div>

            <div className="chats-list-container">
              {chatsListData &&
                [...chatsListData.chats.sort(compareChats)].map((chat) => (
                  <div
                    key={`chat-item-${chat.id}`}
                    className={
                      parseInt(chat.id) === parseInt(currentChatId)
                        ? "cur-selected-chat"
                        : "cur-deselected-chat"
                    }
                    onClick={() => {
                      setCurrentChatId(chat.id);
                    }}
                  >
                    {chat.type === "private" ? (
                      <>
                        {chat.members
                          .filter((member) => member.id !== my_user_id)
                          .map((user) => (
                            <div
                              className="chat-list-item-holder"
                              key={user.id}
                            >
                              <div
                                key={`user-${user.id}`}
                                className="chats-list-item"
                              >
                                <div className="chats-list-item-text-holder">
                                  <h3 className="chat-item-header-text">
                                    {user.display_name ||
                                      user.username ||
                                      "Имя скрыто"}
                                  </h3>
                                  <h4 className="chat-item-header-text-message">
                                    {chat.last_messages &&
                                      [...chat.last_messages]
                                        .slice(-1)
                                        .map((message) => (
                                          <div
                                            key={`last-message-${message.id}`}
                                          >
                                            {message.sender_id ===
                                            my_user_id ? (
                                              <>Вы: </>
                                            ) : (
                                              ""
                                            )}
                                            {parse(
                                              formatMarkdown(
                                                discordProblemSolving(
                                                  message.message_data
                                                )
                                              )
                                            )}
                                          </div>
                                        ))}
                                  </h4>
                                </div>
                              </div>
                              <div
                                key="avatar-holder"
                                className="list-avatar-holder"
                              >
                                <img
                                  key={user.id}
                                  className="list-avatar-src"
                                  src={`https://${window.location.hostname}/api/storage/avatars/${
                                    user.avatars?.split(";")[
                                      user.avatars?.split(";").length - 1
                                    ] || "avatar_placeholder.png"
                                  }`}
                                  alt="USER_AVATAR"
                                />
                              </div>
                            </div>
                          ))}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default Messenger;
