import React, { createContext, useContext, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Cookies from 'js-cookie';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    Cookies.get('authToken') ? `wss://${window.location.hostname}/api/ws/${Cookies.get('authToken')}` : null,
    {
      shouldReconnect: () => true,
      reconnectInterval: 5000,
      share: true
    }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    console.log(`Socket state changed to ${connectionStatus}`)
    if (readyState === ReadyState.OPEN) {
      // sendJsonMessage({
      //   event: "subscribe",
      //   data: {
      //     channel: "general-chatroom",
      //   },
      // })
    }
  }, [readyState])

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const parsedData = JSON.parse(lastMessage.data);
      // console.log('Получено новое сообщение:', parsedData);
      if (parsedData.type==='ping'){
        console.log("Pinging activity")
        sendMessage({ type: "ping", reason: "ping" })
      }
    }
  }, [lastMessage])

  return (
    <SocketContext.Provider value={{ sendMessage, lastMessage, readyState }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
