import "./Main.css";
import { API_SERVER } from "./config.js";

import HeaderTailIcon from "./assets/images/main-page-tail.svg";
import PersonIcon from "./assets/images/face.svg";
import FriendsIcon from "./assets/images/diversity.svg";
import SearchIcon from "./assets/images/search.svg";
import ChatIcon from "./assets/images/chat.svg";
import DataIcon from "./assets/images/data.svg";
import SettingsIcon from "./assets/images/settings.svg";
import CasinoIcon from "./assets/images/casino.svg";
import GameIcon from "./assets/images/joystick.svg";
import TrafficIcon from "./assets/images/traffic.svg";

import axios from "axios";
import Cookies from "js-cookie";
import { Image } from "image-js";
import React, { useState, useEffect, useRef } from "react";

import NotificationComponent from "./components/NotificationComponent.js";
import MediaViewer from "./components/MediaViewer.js";
import ConfirmationDialog from "./components/ConfirmationDialog.js";

import Home from "./screens/Home.jsx";

import Messenger from "./screens/Messenger.jsx";
import Friends from "./screens/Friends.jsx";
import Search from "./screens/Search.jsx";

import MyProfile from "./screens/MyProfile.jsx";
import UserProfile from "./screens/UserProfile.jsx";

import Casino from "./screens/Casino.jsx";
import Game from "./screens/Game.jsx";

import Settings from "./screens/Settings.jsx";

import Test from "./screens/Test.jsx";

const Main = () => {
  const requestOtherUserPageRef = useRef();
  const openChatWithUserRef = useRef();

  const [myUserData, setMyUserData] = useState([]);

  const [friendsRequestsData, setFriendsRequestsData] = useState([]);
  const [friendsData, setFriendsData] = useState([]);

  const [requestedUserId, setRequestedUserId] = useState(null);

  const openChatWithUser = (user_id) => {
    switchTab("messenger");
    if (openChatWithUserRef.current) {
      openChatWithUserRef.current.openChatWithUser(user_id);
    }
  };

  const requestOtherUserPage = (user_id) => {
    setRequestedUserId(user_id);
    localStorage.setItem("lastRequestedUserID", user_id);
    
  };

  const [activeTab, setActiveTab] = useState(() => {
    const tab = localStorage.getItem("activeTab") || "main";
    if (tab === "other-user") {
      requestOtherUserPage(localStorage.getItem("lastRequestedUserID"));
    }
    return tab;
  });

  const switchTab = (tabId) => {
    localStorage.setItem("activeTab", tabId);
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (requestedUserId && requestOtherUserPageRef.current) {
      requestOtherUserPageRef.current.request_user_page(requestedUserId);
      switchTab("other-user");
      setRequestedUserId(null);
    }
  }, [requestedUserId]);  

  ///TODO Переписать репорт активности на серверную часть

  const reportUserActivity = async () => {
    try {
      var session_token = Cookies.get("authToken");
      await axios.post(`https://${window.location.hostname}/api/v1/client/online_ping`, {
        session_token,
      });
      console.log("Online ping");
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      reportUserActivity();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const notificationRef = useRef();
  const mediaViewerRef = useRef();
  const confirmationDialogRef = useRef();

  function showNotification(title, text) {
    if (notificationRef.current) {
      notificationRef.current.showNotification(title, text);
    }
  }

  function openMedia(media_url) {
    if (mediaViewerRef.current) {
      mediaViewerRef.current.openMedia(media_url);
    }
  }

  function openDialog(text, target_func) {
    if (confirmationDialogRef.current) {
      confirmationDialogRef.current.showDialog(text, target_func);
    }
  }

  const refresh_all_user_data = async () => {
    refresh_user_main_info();
    refresh_friends();
  };

  const refresh_user_main_info = async () => {
    try {
      var session_token = Cookies.get("authToken");
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_my_user`,
        { session_token }
      );
      if (response) {
        console.log("My data update");
        setMyUserData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const refresh_friends = async () => {
    const session_token = Cookies.get("authToken");
    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_my_friend_requests`,
        { session_token }
      );
      if (response) {
        console.log(`Friend request data update`);
        setFriendsRequestsData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_my_friends`,
        { session_token }
      );
      if (response) {
        console.log(`Friends data update`);
        setFriendsData(response.data);
      }
    } catch (error) {
      if (error.response) {
        showNotification(`Error: ${error.response.data.detail}`);
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const handleProfileAvatarClick = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > 20 * 1024 * 1024) {
      alert("Файл слишком большой. Максимальный размер - 20 МБ.");
      return;
    }
    try {
      let arrayBuffer = await file.arrayBuffer();
      let image = await Image.load(arrayBuffer);

      let originalWidth = image.width;
      let originalHeight = image.height;

      let targetSize = Math.min(originalWidth, originalHeight);

      let croppedImage = image.crop({
        x: (originalWidth - targetSize) / 2,
        y: (originalHeight - targetSize) / 2,
        width: targetSize,
        height: targetSize,
      });
      let resized_image = croppedImage.resize({ width: 256, height: 256 });
      const resizedImageBuffer = resized_image.toBuffer();
      const blob = new Blob([resizedImageBuffer], { type: "image/png" });

      const formData = new FormData();
      formData.append("session", Cookies.get("authToken"));
      formData.append("file", blob, "avatar.png");

      axios
        .post(`https://${window.location.hostname}/api/v1/client/upload_avatar`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Success avatar upload");
          refresh_user_main_info();
        })
        .catch((error) => {
          console.error("Error:", error.message);
          if (error.response) {
            console.error("Response Status:", error.response.status);
            console.error("Response Data:", error.response.data);
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    refresh_all_user_data();
    document.querySelector(".phonk-video").volume = 0.05;
  }, []);

  return (
    <div className="Main">
      <NotificationComponent ref={notificationRef} />
      <MediaViewer ref={mediaViewerRef} />
      <ConfirmationDialog ref={confirmationDialogRef} />
      <div className="navigation-container">
        <div className="navigation-bar">
          <div
            className="nav-kitsunet-button"
            onClick={() => switchTab("main")}
          >
            <span>Kitsunet</span>
            <img src={HeaderTailIcon} alt=""/>
          </div>
          <span className="nav-breaker"></span>
          <div className="nav-buttons-container">
            <div
              className={`nav-button-item ${
                activeTab === "my-profile" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("my-profile")}
            >
              <img src={PersonIcon} alt="my profile"/>
              <h6>Мой профиль</h6>
            </div>
            <div
              className={`nav-button-item ${
                activeTab === "friends" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("friends")}
            >
              <img src={FriendsIcon} alt="friends"/>
              <h6>Друзья</h6>
            </div>
            <div
              className={`nav-button-item ${
                activeTab === "search" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("search")}
            >
              <img src={SearchIcon} alt="search"/>
              <h6>Поиск</h6>
            </div>
            <div
              className={`nav-button-item ${
                activeTab === "settings" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("settings")}
            >
              <img src={SettingsIcon} alt="settings"/>
              <h6>Настройки</h6>
            </div>
            <div
              className={`nav-button-item nav-button-long-item ${
                activeTab === "messenger" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("messenger")}
            >
              <img src={ChatIcon} alt="messenger"/>
              <h6>Мессенджер</h6>
            </div>
            <div
              className={`nav-button-item ${
                activeTab === "slots" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("slots")}
            >
              <img src={CasinoIcon} alt="slots"/>
              <h6>Слоты</h6>
            </div>
            <div
              className={`nav-button-item ${
                activeTab === "game" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("game")}
            >
              <img src={GameIcon} alt="games"/>
              <h6>Игра</h6>
            </div>
            <div
              className={`nav-button-item ${
                activeTab === "phonk" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("phonk")}
            >
              <img src={TrafficIcon} alt="music"/>
              <h6>Фонк</h6>
            </div>
            <div
              className={`nav-button-item ${
                activeTab === "test" ? "nav-light-button" : ""
              }`}
              onClick={() => switchTab("test")}
            >
              <img src={DataIcon} alt="tests"/>
              <h6>Тесты</h6>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="tab-content"
          style={
            activeTab === "main" ? { display: "block" } : { display: "none" }
          }
        >
          <Home refresh_user_main_info={refresh_user_main_info} />
        </div>

        <div
          id="my-profile"
          className="tab-content"
          style={
            activeTab === "my-profile"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <MyProfile
            my_user_data={myUserData}
            friends_data={friendsData}
            handleProfileAvatarClick={handleProfileAvatarClick}
            switchTab={switchTab}
            request_user_page={requestOtherUserPage}
            openMedia={openMedia}
          ></MyProfile>
        </div>

        <div
          id="test"
          className="tab-content"
          style={
            activeTab === "test" ? { display: "block" } : { display: "none" }
          }
        >
          <Test></Test>
        </div>

        <div
          id="game"
          className="tab-content"
          style={
            activeTab === "game" ? { display: "block" } : { display: "none" }
          }
        >
          <Game></Game>
        </div>

        <div
          id="slots"
          className="tab-content"
          style={
            activeTab === "slots" ? { display: "block" } : { display: "none" }
          }
        >
          <Casino
            balance={myUserData.ticks}
            refresh={refresh_user_main_info}
          ></Casino>
        </div>

        <div
          id="chat"
          className="tab-content"
          style={
            activeTab === "messenger"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <Messenger
            ref={openChatWithUserRef}
            my_user_id={myUserData.id}
            openConfirmationDialog={openDialog}
            request_user_page={requestOtherUserPage}
          ></Messenger>
        </div>

        <div
          id="search"
          className="tab-content"
          style={
            activeTab === "search" ? { display: "block" } : { display: "none" }
          }
        >
          <Search request_page={requestOtherUserPage} switch_tab={switchTab} />
        </div>

        <div
          id="phonk"
          className="tab-content"
          style={
            activeTab === "phonk" ? { display: "block" } : { display: "none" }
          }
        >
          <video className="phonk-video" controls>
            <source src={`https://${window.location.hostname}/api/phonk`} type="audio/mpeg" />
            Your browser does not support the audio element.
          </video>
          <h1>NIGHT DRIVE PHONK MIX (LXST CXNTURY TYPE) #3 - PHONKONAUT</h1>
        </div>
        <div
          id="other-user"
          className="tab-content"
          style={
            activeTab === "other-user"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <UserProfile
            ref={requestOtherUserPageRef}
            openMedia={openMedia}
            friendsRequestsData={friendsRequestsData}
            friendsData={friendsData}
            refresh_friends={refresh_friends}
            openChatWithUser={openChatWithUser}
          />
        </div>
        <div
          id="friends"
          className="tab-content"
          style={
            activeTab === "friends" ? { display: "block" } : { display: "none" }
          }
        >
          <Friends
            refresh_friends={refresh_friends}
            friendsData={friendsData}
            friendsRequestsData={friendsRequestsData}
            switch_tab={switchTab}
            request_page={requestOtherUserPage}
          />
        </div>

        <div
          id="settings"
          className="tab-content"
          style={
            activeTab === "settings"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {myUserData.length !== 0 && (
            <Settings
              my_user_data={myUserData}
              refresh_user_data={refresh_user_main_info}
              add_avatar={handleProfileAvatarClick}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Main;
