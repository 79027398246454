import './MediaViewer.css';
import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { STORAGE_SERVER } from '../config.js'

const NotificationComponent = forwardRef((props, ref) => {
  const [url, setUrl] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const handleCloseButton = () => {
    setIsVisible(false);
  }

  useImperativeHandle(ref, () => ({
    openMedia(media_url) {
      console.warn("OPPENING MEDIA")
      setUrl(media_url);
      setIsVisible(true);
    }
  }));

  return (
    <div className="MediaViewer media-viewer" ref={ref} style={{ display: isVisible? 'flex' : 'none' }}>
        <div className='media-viewer-back' onClick={handleCloseButton}></div>
        <span className="media-viewer-close" onClick={handleCloseButton}>&times;</span>
        <img className="media-viewer-content" src={`https://${window.location.hostname}/api/storage/media/${url}`} alt={url}/>
        <a rel="noreferrer" href={`https://${window.location.hostname}/api/storage/media/${url}`} target="_blank">{`https://${window.location.hostname}/api/storage/media/${url}`}</a>
    </div>
  );
});

export default NotificationComponent;