import "./Casino.css";
import React, { useState } from "react";

import { API_SERVER } from "../config.js";

import axios from "axios";
import Cookies from "js-cookie";

import gif_loading from "../assets/images/loading.gif";

import icon_house from "../assets/images/house.png";
import icon_onigiri from "../assets/images/onigiri.png";
import icon_sakura from "../assets/images/sakura.png";
import icon_bell from "../assets/images/bell.png";
import icon_fox from "../assets/images/fox.png";

const Casino = ({ balance, refresh, setTicks }) => {
  const [rolling, setRolling] = useState(false);

  const [bet, setBet] = useState(10);

  const [slotsIcons, setSlotsIcons] = useState([icon_fox, icon_fox, icon_fox]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSlotMachineSpin = async () => {
    setRolling(true);

    const requested_bet = bet;
    
    const formData = new FormData();
    formData.append("bet", requested_bet);
    formData.append("session", Cookies.get("authToken"));

    try {
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/spin_slots`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success spin");
        setSlotsIcons([gif_loading, gif_loading, gif_loading]);
        revealSlots(response.data);
        setTicks(balance - requested_bet);
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
      setRolling(false);
    }
  };

  async function revealSlots(res_values) {
    for (let i = 0; i < 3; i++) {
      await delay(2000);

      let icon_to_set = icon_onigiri;
      switch (res_values[i]) {
        case "cherry":
          icon_to_set = icon_onigiri;
          break;
        case "fox":
          icon_to_set = icon_fox;
          break;
        case "house":
          icon_to_set = icon_house;
          break;
        case "bell":
          icon_to_set = icon_bell;
          break;
        case "sakura":
          icon_to_set = icon_sakura;
          break;
        default:
          icon_to_set = gif_loading;
      }

      changeSlotsIcons(i, icon_to_set);
    }
    refresh();
    setRolling(false);
  }

  const changeSlotsIcons = (index, target_icon) => {
    setSlotsIcons((prevState) => {
      const newState = [...prevState];
      newState[index] = target_icon;
      return newState;
    });
  };

  const handleLowerBet = () => {
    if (bet > 10) {
      setBet(bet - 10);
    }
  };

  const handleRaiseBet = () => {
    if (bet < 10000) {
      setBet(bet + 10);
    }
  };

  return (
    <div className="Casino slot-machine-container">
      <div className="slot-machine">
        <h1>Баланс: {balance} тиков</h1>
        <div className="wheels-container">
          <div className="wheel">
            <div className="item">
              <img className="wheel-icon" src={slotsIcons[0]} alt="" />
            </div>
          </div>
          <div className="wheel">
            <div className="item">
              <img className="wheel-icon" src={slotsIcons[1]} alt="" />
            </div>
          </div>
          <div className="wheel">
            <div className="item">
              <img className="wheel-icon" src={slotsIcons[2]} alt="" />
            </div>
          </div>
        </div>

        <div className="slot-machine-bet-counter">
          <button
            style={{ width: "min" }}
            className="kitsunet-design-input-button"
            onClick={handleLowerBet}
            disabled={rolling}
          >
            -
          </button>
          <h2 className="slot-machine-bet-counter-value">{bet}</h2>
          <button
            style={{ width: "min" }}
            className="kitsunet-design-input-button"
            onClick={handleRaiseBet}
            disabled={rolling}
          >
            +
          </button>
        </div>

        <button
          style={{ width: "100px" }}
          className="kitsunet-design-input-button"
          onClick={handleSlotMachineSpin}
          disabled={rolling}
        >
          Играть
        </button>
      </div>

      <div className="slot-machine-bonuses">
        <div className="slot-machine-bonuses-row">
          <h2>Множители ставки</h2>
        </div>
        <div className="slot-machine-bonuses-row">
          <h2>x3</h2>
          <img className="bonuses-icon" src={icon_fox} alt="" />
          <h2>= x50</h2>
        </div>
        <div className="slot-machine-bonuses-row">
          <h2>x3</h2>
          <img className="bonuses-icon" src={icon_sakura} alt="" />
          <h2>= x25</h2>
        </div>
        <div className="slot-machine-bonuses-row">
          <h2>x3</h2>
          <img className="bonuses-icon" src={icon_house} alt="" />
          <h2>= x15</h2>
        </div>
        <div className="slot-machine-bonuses-row">
          <h2>x3</h2>
          <img className="bonuses-icon" src={icon_onigiri} alt="" />
          <h2>= x10</h2>
        </div>
        <div className="slot-machine-bonuses-row">
          <h2>x3</h2>
          <img className="bonuses-icon" src={icon_bell} alt="" />
          <h2>= x5</h2>
        </div>
      </div>
    </div>
  );
};

export default Casino;
