import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import "./MyProfile.css";
import { STORAGE_SERVER, API_SERVER } from "../config";
import icon_pencil from "../assets/images/pencil.svg";
import { Image } from "image-js";

const MyProfile = ({
  my_user_data,
  friends_data,
  handleProfileAvatarClick,
  request_user_page,
  switchTab,
  openMedia,
}) => {
  function formatDateTime(date) {
    const givenDate = new Date(date);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - givenDate.getTime());

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffYears = Math.floor(diffDays / 365);

    let formattedTime = givenDate.toLocaleTimeString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });

    let formattedDate = "";
    if (givenDate.getDate() !== currentDate.getDate()) {
      formattedDate = givenDate.toLocaleDateString("ru-RU", {
        day: "numeric",
        month: "short",
      });
    }

    let formattedYear = "";
    if (diffYears >= 1) {
      formattedYear = ` ${givenDate.getFullYear()}`;
    }

    return `${formattedYear} ${formattedDate} ${formattedTime}`;
  }

  const [postsData, setPostsData] = useState();

  const [postText, setPostText] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const avatarHiddenInputRef = useRef();
  const postAddMediaHiddenInputRef = useRef();

  const refresh_user_posts_info = async (target_page) => {
    try {
      const formData = new FormData();
      formData.append("target_user_id", 0);
      formData.append("session_token", Cookies.get("authToken"));
      formData.append("page", target_page);
      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/data/get_user_posts`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response && response.data) {
        console.log(`My posts update`);

        const newPosts = response.data.posts.filter(
          (post) =>
            !postsData?.posts.some(
              (existingPost) => existingPost.id === post.id
            )
        );

        if (!postsData || !Array.isArray(postsData.posts)) {
          setPostsData(response.data);
        } else {
          setPostsData((prevPosts) =>
            prevPosts
              ? {
                  ...prevPosts,
                  posts: [...newPosts, ...prevPosts.posts],
                  total_pages: response.data.total_pages,
                }
              : response.data
          );
        }
        setTotalPages(response.data.total_pages);
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    }
  };

  const handleAddMediaToPost = async (event) => {
    const file = event.target.files[0];
    if (file.size > 20 * 1024 * 1024) {
      alert("Файл слишком большой. Максимальный размер - 20 МБ.");
      return;
    }
    const arrayBuffer = await file.arrayBuffer();

    setSelectedImages((selectedImages) => [...selectedImages, arrayBuffer]);
  };

  const handleSubmitPost = async () => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("text", postText);
      for (const post_media_buffer of selectedImages) {
        let res_image = await Image.load(post_media_buffer);

        const resizedImageBuffer = res_image.toBuffer();
        const blob = new Blob([resizedImageBuffer], { type: "image/png" });
        formData.append("media", blob);
      }
      formData.append("session", Cookies.get("authToken"));

      const response = await axios.post(
        `https://${window.location.hostname}/api/v1/client/upload_post`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        console.log("Success posted");
        setPostsData();
        refresh_user_posts_info(1);

        setSelectedImages([]);
        setPostText("");
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.warn(
          "Response Status:",
          error.response.status,
          "Response Data:",
          error.response.data
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const bottomOfWindow =
        window.innerHeight + 1 + window.scrollY >= document.body.offsetHeight;
      if (bottomOfWindow && !isLoading && page <= totalPages) {
        refresh_user_posts_info(page + 1);
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    refresh_user_posts_info(1);
  }, []);

  return (
    <div className="MyProfile">
      {my_user_data.length === 0 ? (
        <h1 className="profile-page-posts-post-container">Загрузка</h1>
      ) : (
        <>
          <div className="profile-main-info-container">
            <div
              className="profile-avatar"
              onClick={() => avatarHiddenInputRef.current.click()}
            >
              <img
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
                alt="Avatar"
                src={`https://${window.location.hostname}/api/storage/avatars/${
                  my_user_data.avatars?.split(";")[
                    my_user_data.avatars?.split(";").length - 1
                  ] || "avatar_placeholder.png"
                }`}
              />
              <input
                ref={avatarHiddenInputRef}
                type="file"
                accept=".jpeg,.jpg,.png"
                onChange={handleProfileAvatarClick}
                style={{ display: "none" }}
              />
            </div>
            <div className="profile-text">
              <h2 className="profile-name">
                {my_user_data.display_name || my_user_data.username || "Анон"}
              </h2>
              <div className="profile-description">
                {my_user_data.description || "Тут пустовато"}
              </div>
            </div>
          </div>
          <div className="profile-page-content-container">
            <div className="profile-page-posts-container">
              <div className="profile-page-posts-create-post-container">
                <div className="profile-page-posts-create-post-container-form">
                  <div className="profile-page-posts-create-post-text">
                    <input
                      className="kitsunet-design-input-field"
                      type="text"
                      placeholder="Введите текст"
                      value={postText}
                      onChange={(e) => setPostText(e.target.value)}
                      required
                    />
                  </div>
                  <button
                    className="kitsunet-design-input-button"
                    onClick={handleSubmitPost}
                    disabled={isLoading}
                    value="Опубликовать"
                  >
                    Опубликовать
                  </button>
                </div>

                <div className="profile-page-posts-create-post-media-manager-container">
                  <div className="profile-page-posts-create-post-media-manager-added-images-container">
                    {selectedImages.map((buffer) => (
                      <img
                        className="profile-page-posts-create-post-media-manager-image"
                        src={URL.createObjectURL(
                          new Blob([buffer], { type: "image/jpeg" })
                        )}
                        alt={`${buffer}`}
                      />
                    ))}
                  </div>
                  <img
                    onClick={() => postAddMediaHiddenInputRef.current.click()}
                    className="profile-page-posts-create-post-add-media-button"
                    src={icon_pencil}
                    alt=""
                  />

                  <input
                    ref={postAddMediaHiddenInputRef}
                    type="file"
                    accept=".jpeg,.jpg,.png"
                    onChange={handleAddMediaToPost}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              {postsData && (
                <>
                  {postsData.posts.length === 0 ? (
                    <h2 className="profile-page-posts-post-container">
                      Вы ещё не делали посты
                    </h2>
                  ) : (
                    [...postsData.posts].reverse().map((post) => (
                      <div
                        key={post.id}
                        className="profile-page-posts-post-container"
                      >
                        <div className="profile-page-posts-post-text">
                          {post.text}
                        </div>
                        {post.media && (
                          <div className="profile-page-posts-post-media">
                            {post.media.split(";").map((mediaItem) => (
                              <img
                                className="profile-page-posts-post-media-src"
                                key={mediaItem}
                                src={`https://${window.location.hostname}/api/storage/media/${mediaItem}`}
                                alt={mediaItem}
                                onClick={() => openMedia(mediaItem)}
                              />
                            ))}
                          </div>
                        )}
                        <div className="profile-page-posts-post-footer">
                          {formatDateTime(post.created_at)}
                        </div>
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
            <div className="profile-page-additional-panel">
              <div className="profile-page-additional-panel-info">
                <div className="profile-page-additional-panel-info-container">
                  <div className="profile-page-additional-panel-info-title">
                    Рейтинг:
                  </div>
                  <div className="profile-page-additional-panel-info-text">
                    {my_user_data.social_score}
                  </div>
                </div>

                <div className="profile-page-additional-panel-info-container">
                  <div className="profile-page-additional-panel-info-title">
                    Баланс:
                  </div>
                  <div className="profile-page-additional-panel-info-text">
                    {my_user_data.ticks}
                  </div>
                </div>

                {my_user_data.location && (
                  <div className="profile-page-additional-panel-info-container">
                    <div className="profile-page-additional-panel-info-title">
                      Местоположение:
                    </div>
                    <div className="profile-page-additional-panel-info-text">
                      {my_user_data.location}
                    </div>
                  </div>
                )}
              </div>
              <div className="profile-page-additional-panel-friends">
                <h3 className="profile-page-additional-panel-friends-title">
                  Друзья:
                </h3>
                {friends_data.friends &&
                  (friends_data.friends.length === 0 ? (
                    <h2>Пока их нет</h2>
                  ) : (
                    friends_data.friends.map((user) => (
                      <div
                        className="profile-page-additional-panel-friend-container"
                        key={user.user_id}
                        onClick={() => {
                          request_user_page(user.user_id);
                          switchTab("other-user");
                        }}
                      >
                        <div className="profile-page-additional-panel-friend-avatar">
                          <img
                            className="profile-page-additional-panel-friend-avatar-src"
                            src={`https://${window.location.hostname}/api/storage/avatars/${
                              user.avatars?.split(";")[
                                user.avatars?.split(";").length - 1
                              ] || "avatar_placeholder.png"
                            }`}
                            alt="USER_AVATAR"
                          />
                        </div>
                        <div className="profile-page-additional-panel-friend-text-container">
                          <div className="profile-page-additional-panel-friend-text-username">
                            {user.display_name || user.username || "Имя скрыто"}
                          </div>
                          <div className="profile-page-additional-panel-friend-text-status">
                            {formatDateTime(user.last_seen)}
                          </div>
                        </div>
                      </div>
                    ))
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyProfile;
